import { useState } from 'react';
import '/assets/styles/sections/home/contact.scss';

// Partials
import { Input, Form, FormFeedback} from '/views/partials/forms'
import Btn from '/views/partials/btn'

// Services
import siteServices from '/services/site'

export const Contact = () => {
  const [feedbackData, setFeedbackData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = (formFields) => {
    setSubmitting(true);
    setFeedbackData(null);

    siteServices.submitContactForm(formFields).then(() => {
      setSuccess(true);
    }).catch((feedback) => {
      setFeedbackData(feedback);
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <section className="section home-contact">
      <div className="contact-wrap wrapper">
        <h2 className="contact-title">Contact Us</h2>
        {!success &&
          <Form
            className="contact-form grid-container"
            onSubmit={submit}>
            <FormFeedback inGrid data={feedbackData} />
            <div className="row">
              <div className="col">
                <Input
                  disabled={submitting}
                  type="text"
                  name="name"
                  label="Your Name"
                  validation={{ required: 'Please enter your name.' }} />
              </div>
            </div>
            <div className="row">
              <div className="col x6">
                <Input
                  disabled={submitting}
                  type="email"
                  name="email"
                  label="Your Contact Email"
                  validation={{
                    email: 'Please enter a valid email address.',
                    required: 'Please enter your email.'
                  }} />
              </div>
              <div className="col x6">
                <Input
                  disabled={submitting}
                  type="phone"
                  name="phone"
                  label="Your Phone Number"
                  validation={{
                    phone: 'Please enter a valid telephone number.',
                    required: 'Please enter your phone number.'
                  }} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  disabled={submitting}
                  type="textarea"
                  name="message"
                  label="Your Message"
                  validation={{
                    required: 'Please enter your message.'
                  }} />
              </div>
            </div>
            <div className="row">
              <div className="contact-submitbtn col al-center">
                <Btn
                  disabled={submitting}
                  loading={submitting}
                  afterIcon="angle-right"
                  className="text big"
                  type="submit">
                  Submit
                </Btn>
              </div>
            </div>
          </Form>
        }
        {success &&
          <div className="contact-success">
            <p className="success-message"><strong>Thank you.</strong> We have received your contact form successfully. We will get back to you as soon as possible.</p>

            <Btn
              onClick={() => { setSuccess(false); }}
              className="success-resetbtn text big">
              Send another message
            </Btn>
          </div>
        }
      </div>
    </section>
  )
}

export default Contact;