import { useRef } from 'react';
import '/assets/styles/pages/home.scss';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img'
import Icon from '@wearetla/tla-essentials-tools/partials/icon'

// Sections
import Services from '/views/sections/home/services';
import Contact from '/views/sections/home/contact';
import Brands from '/views/sections/home/brands';

// Static Assets
import image_logo from '/assets/images/logo-white.svg'

const Homepage = () => {
	const contentContainerRef = useRef();
	
	const scrollToContent = (e) => {
		e.preventDefault();
		contentContainerRef?.current?.scrollIntoView({
			behavior: 'smooth'
		});
	}

	return (
		<main>
			<section className="home-intro">
				<h1 className="intro-content">
					<span className="content-pretitle">We are</span>
					<Img
						className="content-logo"
						src={image_logo}
						title="TLA"
						alt="TLA" />
					<span className="content-description">
						where we infuse every project with the creative zeal of an amateur and the seasoned skills of a professional
					</span>
				</h1>

				<button
					onClick={scrollToContent}
					type="button"
					className="intro-scrollbtn">
					<Icon name="angle-down" />
				</button>
			</section>

			<Services ref={contentContainerRef} />

			<Brands />

			<Contact />
		</main>
	)
}

export default Homepage