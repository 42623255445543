import request from '@wearetla/tla-essentials-tools/utilities/request';

const siteServices = {
  submitContactForm: (formData) => {
    return new Promise((resolve, reject) => {
      request.post('https://api.web3forms.com/submit', {
        ...formData,
        access_key: '2599f6a6-8b86-45cd-95c7-723d8783c0e8',
      }, (payload, status) => {
        if (status === 200) {
          resolve();
        }
        else {
          reject(request?.data?.meta?.messages?.error?.length ? request?.data?.meta?.messages : 'An error occured while sending your message. Please try again later.');
        }
      }, { external: true });

      // request.post('contact-form', formData, (payload, status) => {
      // 	if(status === 200) {
      // 		resolve();
      // 	}
      // 	else {
      // 		reject(request?.data?.meta?.messages?.error?.length ? request?.data?.meta?.messages : 'An error occured while sending your message. Please try again later.');
      // 	}
      // });
    });
  }
}

export default siteServices;