import "/assets/styles/sections/home/brands.scss";

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img'

// Static Assets
import image_brand_kahvedunyasi from "/assets/images/brands/kahvedunyasi.svg";
import image_brand_if from "/assets/images/brands/if.svg";
import image_brand_ifsokak from "/assets/images/brands/ifsokak.svg";
import image_brand_babymall from "/assets/images/brands/babymall.svg";

const Brands = () => {
    return (
        <section className="section home-brands">
            <div className="wrapper">
                <h3 className="brands-title">Brands</h3>

                <div className="brands-content wysiwyg">
                    <p>
                        We add value to strong brands through creative
                        solutions. By developing projects in strategy,
                        design, and communication, we help our brands build
                        lasting connections with their target audiences.
                    </p>
                    <p>
                        From dining to entertainment, shopping to
                        socializing, we deliver innovative and
                        attention-grabbing solutions for our partners who
                        touch every moment of life. Together with these
                        industry-leading brands, we continue to make a
                        difference.
                    </p>
                </div>
            </div>

            <div className="brands-list">
                <div className="wrapper">
                <ul className="list-container">
                    <li className="list-brand">
                    <Img className="brand-logo" src={image_brand_kahvedunyasi} alt="Kahve Dünyası" />
                    </li>
                    <li className="list-brand">
                    <Img className="brand-logo" src={image_brand_if} alt="IF" />
                    </li>
                    <li className="list-brand">
                    <Img className="brand-logo" src={image_brand_babymall} alt="Baby Mall" />
                    </li>
                    <li className="list-brand">
                    <Img className="brand-logo" src={image_brand_ifsokak} alt="IF Sokak" />
                    </li>
                </ul>
                </div>
            </div>
        </section>
    );
};

export default Brands;
