import { forwardRef } from "react";
import "/assets/styles/sections/home/services.scss";

const Services = (props, contentContainerRef) => {
    return (
        <main className="section home-services" ref={contentContainerRef}>
            <div className="services-heading">
                <div className="heading-wrap wrapper">
                    <h2 className="heading-title">What we Do</h2>

                    <p className="heading-content">
                        With a fresh perspective in digital transformation, we
                        assist leading companies in crafting intuitive, visually
                        striking, and reliable digital experiences for the
                        modern customer landscape.
                    </p>
                </div>
            </div>

            <section className="services-section">
                <div className="wrapper">
                    <h3 className="section-title">Design</h3>

                    <ul className="section-points">
                        <li>User Research & Audit</li>
                        <li>User Experience (UX) Design</li>
                        <li>User Interface (UI) Design</li>
                    </ul>
                </div>
            </section>
            <section className="services-section">
                <div className="wrapper">
                    <h3 className="section-title">Technology & Engineering</h3>

                    <ul className="section-points">
                        <li>Web Application</li>
                        <li>Mobile Applications</li>
                        <li>Online Payment Solutions</li>
                        <li>E-Commerce Solutions</li>
                        <li>Business Solutions</li>
                    </ul>
                </div>
            </section>
            <section className="services-section">
                <div className="wrapper">
                    <h3 className="section-title">Marketing & Analytics</h3>

                    <ul className="section-points">
                        <li>Search Engine Optimization (SEO)</li>
                        <li>Search Engine Marketing (SEM)</li>
                        <li>Programmatic</li>
                        <li>Analytics</li>
                    </ul>
                </div>
            </section>
        </main>
    );
};

const ServicesWithRef = forwardRef(Services);

export default ServicesWithRef;
